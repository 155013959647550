jQuery('body').on('click', '.age-gate-submit-yes', function() {
        
    setTimeout(
		
		function() {  
			
			jQuery(window).delay( 500 ).trigger( 'resize' ); 
			
		}, 500 );
        
});




jQuery( document ).on( 'agegatepassed', function() { 
	
	alert('passed');
	
});

jQuery( document ).on( 'agegateshown', function() { 
	
	alert('shown');
	
});