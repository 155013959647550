jQuery( ".faq-nav > ul > li" ).click(function(){
	
	var navClass = jQuery(this).attr("class");
	
	var formattedClass = 'li.accordion-item.' + navClass;
	
	var dotClass = '.' + navClass;
	
	jQuery( 'li.accordion-item' ).not( dotClass ).hide();
	
	jQuery( formattedClass ).show();

});


jQuery( ".faq-nav > h2" ).click(function(){
	
	jQuery( 'li.accordion-item' ).show();

});