jQuery("#gform_fields_2 > .gfield > .ginput_container_radio > .gfield_radio > li").click(function(e){
	
	e.stopPropagation();
	
	jQuery( this ).children('input').prop("checked", true);
	
	jQuery( this ).addClass('selected');
	
	jQuery( this ).siblings().removeClass('selected');

});