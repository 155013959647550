jQuery.fn.extend({
    hasClasses: function (selectors) {
        var self = this;
        for (var i in selectors) {
            if (jQuery(self).hasClass(selectors[i])) 
                return true;
        }
        return false;
    }
});

jQuery(window).on('load', function() {
	
	 jQuery('.off-canvas-content').addClass('loaded');
	 jQuery('.marimo-loader').hide();
	 
	 //slide in elements
	 jQuery('.off-canvas-content').children().addClass('animated');
	 jQuery('.off-canvas-content').children().addClass('fadeIn');
	 
	 //scrollbutton
	 jQuery("#scrollButton").addClass("downArrow");
	 jQuery( '#scrollButton' ).addClass( 'animated' );
	 jQuery( '#scrollButton' ).addClass( 'bounceInUp' );
	 
	
	var wrapper = jQuery('body');
		
	if ( wrapper.outerHeight(true) < jQuery(window).height() ) {
			jQuery('#scrollButton').hide();
	}
	 	 
	 //a workaround
	 jQuery("#scrollButton").on("mouseover", function(){
		  jQuery( '#scrollButton' ).removeClass( 'bounceInUp' );
	});
	 
	 jQuery("#scrollButton").on("click", function(){
				
	 	if ( jQuery( '#scrollButton' ).hasClass( 'downArrow' ) ) {
	 		jQuery( "html, body" ).animate({scrollTop: jQuery(document).height()}, 'slow'); 
		
		}
			    
		if ( jQuery( '#scrollButton' ).hasClass( 'upArrow' ) ) {
			jQuery( "html, body" ).animate({ scrollTop: 0 }, 'slow');
		
		}
		
		jQuery("#scrollButton").blur();
		
	      
});
	 
			  
}).each(function() {
				
	 if(this.complete) jQuery(this).load();
	 
	 jQuery('#scrollButton').removeClass('bounceInUp');
			  
});

/*
jQuery('a').click(function(e) {
	
	if ( jQuery( this ).attr( 'href' ) === undefined ) { 
		
	}
	
	if ( jQuery( this ).hasClass( 'gallery_image' ) ) { 
		
	}
	
	if ( jQuery( this ).hasClass( 'hamburger' ) ) { 
		
	}
	
	if ( jQuery( this ).hasClasses(['sl-button', 'slick-next', 'slick-prev', 'browser-bar']) ) {
			
	}
	
	else {
				
		hostname = new RegExp(location.host);
		// Store current link's url
		var url = jQuery(this).attr("href");
			  
		 // Test if current host (domain) is in it
		if( hostname.test( url ) ) {
				
			// If it's local...
			e.preventDefault();
			newLocation = this.href;
			jQuery('.off-canvas-content').removeClass('loaded');
			jQuery('.marimo-loader').show();
			window.location = newLocation;
		}
				  
		else if(url.slice(0, 1) == "/"){
				e.preventDefault();
				newLocation = this.href;
				jQuery('.off-canvas-content').removeClass('loaded');
				jQuery('.marimo-loader').show();
				window.location = newLocation;		    
		}
		
		else if(url.slice(0, 1) == "#"){
				
				if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
					
					var target = jQuery(this.hash);
					target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
					
					if (target.length) {
						jQuery('html,body').animate({
						scrollTop: target.offset().top
		        		}, 1000);
						return false;
		      		}
	    			}
			}
			  
			else {
				e.preventDefault();
				newLocation = this.href;
				jQuery('.off-canvas-content').removeClass('loaded');
				jQuery('.marimo-loader').show();
				window.location = newLocation;                  
	 	}
 	}
}});


function Reload() {
try {
var headElement = document.getElementsByTagName("head")[0];
if (headElement && headElement.innerHTML)
headElement.innerHTML += "<meta http-equiv=\"refresh\" content=\"1\">";
}
catch (e) {}
}

/*
jQuery(function() {
  jQuery('a[href*=#]:not([href=#])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = jQuery(this.hash);
      target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        jQuery('html,body').animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });
}); */
			
						
jQuery( window ).scroll( function() {
				
	if ( jQuery( window ).scrollTop()  > jQuery( window ).height() / 2 ) {
		
		jQuery( "#scrollButton" ).removeClass( "downArrow" );
		jQuery( "#scrollButton" ).addClass( "upArrow" );
		
	}
   				
   	else {
	   				
	   jQuery("#scrollButton").addClass("downArrow");
	   jQuery("#scrollButton").removeClass("upArrow");
			        
	}
	
});