var state = jQuery("#input_4_4_4");
var country = jQuery( '#input_4_4_6 option:not(:selected)' );

var up = jQuery("#input_4_11");
var fun = jQuery("#input_4_15");
var chill = jQuery("#input_4_17");
var dream = jQuery("#input_4_16");
var total = jQuery(".est-total");
var submit = jQuery("#gform_submit_button_4");

var coupon =  jQuery("#input_4_6");
var promo_btn = jQuery( '.promo-btn' );
var code_notice = jQuery('.code-notice');

var est = jQuery('#input_4_24');

function PT_Total() {
	
	var up_total = up.val();
	var fun_total = fun.val();
	var chill_total = chill.val();
	var dream_total = dream.val();
	
	var total_val = parseInt(up_total) + parseInt(fun_total) + parseInt(chill_total) + parseInt(dream_total);
	
	var price = total_val * 60;
	var display_price = "$" + price;
	
	if ( total_val == 0 ) {
		total.text("N/A");
		submit.prop("disabled",true);
		submit.val("Select a Tone");
	}
	
	else {
		
		if ( jQuery( '.valid' ).length > 0 ) {
			
			var promo_math = parseInt( price ) - ( parseInt( price ) * .1 );
			var display_price = "$" + promo_math;
			total.text(display_price);
			est.val( display_price );
			submit.attr("disabled",false);
			submit.val("Submit Order");
			
		}
		
		else {
			
			total.text(display_price);
			est.val( display_price );
			submit.attr("disabled",false);
			submit.val("Submit Order");
			
		}
		
	}
	
}

function check_coupon() {
		
	var code = coupon.val();
	
	var data = {
		'action' : 'check_coupon_code',
		'CODE' : code
	}	
		
	if ( code.length > 0 ) {
		
		promo_btn.addClass( 'load' );
			
		jQuery.post( check_coupon_code.ajax_url, data, function( response ) { 
		
			if( response ) {
				
				var code_formatted = code.toUpperCase();
				
				var success_text = "Success! Enjoy 10% off with code: " + code_formatted;
				
				code_notice.removeClass( 'invalid' );
				code_notice.addClass( 'valid' );
				code_notice.text( success_text );
				
				PT_Total();
				
				promo_btn.removeClass( 'load' );
				
			}
					 
			else {
				
				code_notice.removeClass( 'valid' );
				code_notice.addClass( 'invalid' );
				code_notice.text( 'Invalid Promo Code.' );
				
				PT_Total();
				
				promo_btn.removeClass( 'load' );
				
			}	
			
		});
		
	}

}

jQuery( ".add-btn" ).click( function( event ) {
	
	event.preventDefault();
	
	var input = jQuery( this ).siblings( '.ginput_container' ).children( 'input' );
	
	var current_val = input.val();
	
	var math = parseInt( current_val ) + 1;
	
	input.val(math);
	input.attr( 'value', math );
	
	PT_Total();
  
});

jQuery( ".sub-btn" ).click( function( event ) {
	
	event.preventDefault();
	
	var input = jQuery( this ).siblings( '.ginput_container' ).children( 'input' );
	
	var current_val = input.val();
	
	var math = parseInt( current_val ) - 1;
	
	if ( math < 0 ) {
		
		input.val( "0" );
		input.attr( 'value', "0" );
		
	}
	
	else {
		
		input.val( math );
		input.attr( 'value', math );
		
	}
	
	PT_Total();
  
});

promo_btn.click( function(event) {
	event.preventDefault();
	check_coupon();
	
});

up.change(function() {
	
	PT_Total();
	
});

fun.change(function() {
	
	PT_Total();
	
});

chill.change(function() {
	
	PT_Total();
	
});

dream.change(function() {
	
	PT_Total();
	
});

function PT_init() {
	
	state.attr("readonly","readonly");
	country.attr("disabled","disabled");
	up.attr("readonly","readonly");
	fun.attr("readonly","readonly");
	chill.attr("readonly","readonly");
	dream.attr("readonly","readonly");
	
	check_coupon();
	PT_Total();
	
}

jQuery(window).on('load', function() {
	
	
	if( jQuery('body').hasClass('page-template-template-orderform') ) {
		
		PT_init();
		
	}
	
	
});


jQuery(document).bind('gform_post_render', function(){ 
	
	if( jQuery('body').hasClass('page-template-template-orderform') ) {
	
		PT_init();
		
	}
	
});
