jQuery(document).ready(function(){
	
	
	 jQuery('.slider-full').slick({
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  arrows: false,
	  fade: true,
	  asNavFor: '.slider-thumbs',
	  draggable: false,
	  infinite: true
	});
	
	jQuery('.slider-thumbs').slick({
	  slidesToShow: 7,
	  slidesToScroll: 1,
	  asNavFor: '.slider-full',
	  dots: false,
	  centerMode: true,
	  focusOnSelect: true,
	  draggable: false,
	  infinite: true
	});
	 
});