jQuery( ".privacy-nav > ul > li" ).click(function(){
	
	var navClass = jQuery(this).attr("class");
	
	var formattedClass = '.section.' + navClass;
	
	var dotClass = '.' + navClass;
	
	jQuery( '.section' ).not( dotClass ).hide();
	
	jQuery( formattedClass ).show();

});


jQuery( ".privacy-nav > h2" ).click(function(){
	
	jQuery( '.section' ).show();

});